import Primary from './Primary'
import Secondary from './Secondary'
import SecondaryAlternative from './SecondaryAlternative'

const Buttons = {
  Primary,
  Secondary,
  SecondaryAlternative
}

export default Buttons
