import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import tw from 'twin.macro'

import { MainLayout } from '@common/layouts';
import { SEO } from '@components'

const Title = styled.h1`
  ${tw`text-3xl mx-6 my-10 font-semibold text-center text-gray-900 leading-8`}
  ${tw`md:(text-4xl my-20)`}
`;

const Pagination = styled.div`
  ${tw`flex space-x-2 justify-center mb-8`}
`

const PageLink = styled(Link)`
`;

const CurrentPageLink = styled.span`
  ${tw`font-bold`}
`;

const PaginationLayout = ({ currentPage, numPages, title, description, path, children, Layout = MainLayout }) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? `/${path}` : `/${path}/${(currentPage - 1).toString()}`;
  const nextPage = `/${path}/${(currentPage + 1).toString()}`;
  const pages = [];

  if (numPages > 1) {
    let startPage = currentPage - 2;
    let endPage = currentPage + 2;

    if (startPage <= 0) {
      endPage -= (startPage - 1);
      startPage = 1;
    }

    if (endPage > numPages) {
      startPage -= (endPage - numPages);
      endPage = numPages;
    }

    if (startPage <= 0) startPage = 1;

    for (let i = startPage; i <= endPage; i++) pages.push(i);
  } else pages.push(1);

  const paginationTitle = currentPage > 1 ? `${title} - Page ${currentPage}` : title;

  const StyledLayout = styled(Layout)`${tw`mx-auto max-w-screen-xl`}`;

  return (
    <StyledLayout>
      <SEO title={paginationTitle} description={description} />

      <Title>{title}</Title>

      {children}

      {pages.length > 1 && <Pagination>
        {!isFirst && (
          <Link to={prevPage} rel="prev">
            ← Previous Page
          </Link>
        )}
        {pages.map((i) => {
          const ItemLink = currentPage === i ? CurrentPageLink : PageLink;
          return (
            <ItemLink key={`${path}${i}`} to={`/${path}${i === 1 ? "" : `/${i}`}`}>
              {i}
            </ItemLink>
          );
        }
        )}
        {!isLast && (
          <Link to={nextPage} rel="next">
            Next Page →
          </Link>
        )}
      </Pagination>}
    </StyledLayout>
  )
}

export default PaginationLayout
