import React, { useState } from 'react'
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';

const MapContainer = ({ google, mapPoints }) => {
  const [activeMarker, setActiveMarker] = useState({});
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [infoWindowText, setInfoWindowText] = useState('');

  const initialMapAdjust = (mapProps, map) => {
    const bounds = new google.maps.LatLngBounds();

    mapPoints.forEach(marker => {
      const { lat, lng } = marker.position;

      bounds.extend(new google.maps.LatLng(lat, lng));
    });

    map.fitBounds(bounds);
  };

  const onMarkerClick = (props, marker) => {
    setActiveMarker(marker);
    setShowingInfoWindow(true);
    setInfoWindowText(props.title);
  };

  const onInfoWindowClose = () => {
    setActiveMarker(null);
    setShowingInfoWindow(false);
  }

  return (
    <Map google={google} onReady={initialMapAdjust}>
      {mapPoints.map((mapPoint) => {
        return (
          <Marker title={mapPoint.label} position={mapPoint.position} onClick={onMarkerClick} />
        );
      })}
      <InfoWindow
        marker={activeMarker}
        onClose={onInfoWindowClose}
        visible={showingInfoWindow}>
        <div>
          <h1>{infoWindowText}</h1>
        </div>
      </InfoWindow>
    </Map>
  );
}

export default GoogleApiWrapper({ apiKey: 'AIzaSyD11CED_YxtI6kk1o6_4Auw_JNGFvVfp1Q' })(MapContainer)
