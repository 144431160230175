import React from 'react'
import tw, { styled } from 'twin.macro'

import { SEO, WebinarHeader } from '@components'

import WebinarFooter from './components/WebinarFooter'

const Wrapper = styled.div`
  ${tw`w-full h-full`}
`

const Content = styled.section`
  ${tw`min-h-content w-full`}
`

const WebinarLayout = ({ children, className }) => (
  <>
    <SEO />
    <Wrapper>
      <WebinarHeader />

      <Content className={className} >
        {children}
      </Content>

      <WebinarFooter />
    </Wrapper>
  </>
)

export default WebinarLayout
