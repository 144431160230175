import React from 'react'
import p from 'prop-types'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'

const SEO = ({ site, title, description, localImage, image, article }) => {
  const { pathname } = useLocation()

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || `${siteUrl}${localImage || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet
      title={seo.title}
      titleTemplate={titleTemplate}
      htmlAttributes={{
        lang: `en`,
      }}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:image" content={seo.image} />
      {(article ? <meta property="og:type" content="article" /> : <meta property="og:type" content="website" />)}

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={seo.url} />
      <meta property="twitter:title" content={seo.title} />
      <meta property="twitter:description" content={seo.description} />
      <meta property="twitter:image" content={seo.image} />
    </Helmet>
  )
}

SEO.propTypes = {
  title: p.string,
  description: p.string,
  localImage: p.string,
  image: p.string,
  article: p.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

export default SEO
