import Inner from './Inner'
import LandingPage from './LandingPage'
import Modal from './Modal'
import NewModal from './NewModal'

const Newsletter = {
  Inner,
  LandingPage,
  Modal,
  NewModal,
}

export default Newsletter
