import React from 'react';
import styled from 'styled-components'
import tw from 'twin.macro'

const Icon = styled.img`
  ${tw`mr-2`}
`

const StyledButton = styled.span`
  ${tw`bg-new-primary-bg text-new-primary-color font-bold h-10 px-3 inline-flex text-center items-center`}
`

export default function Primary({ className, icon, children }) {
  return (
    <StyledButton className={className}>
      {icon && <Icon src={icon} alt={children} />}
      {children}
    </StyledButton>
  )
}