import React, { useState } from 'react'
import BackgroundImage from 'gatsby-background-image'
import tw, { styled } from 'twin.macro'

import Form from './Form'

const Wrapper = styled.section`
  ${tw`flex w-full h-100 overflow-hidden`}
`

const Inner = styled.div`
  ${tw`flex w-full h-full px-6 bg-gray-200 text-gray-800`}
`

const BgImage = styled(BackgroundImage)`
  ${tw`flex w-full h-full px-6 bg-gray-200 text-black`}
`

const Content = styled.div`
  ${tw`flex flex-col justify-center items-center mx-auto`}
`

const Title = styled.h4`
  ${tw`text-2xl font-normal mb-4`}
`

const Subtitle = styled.p`
  ${tw`mb-8 text-center`}
`

const Switcher = ({ bgImage, children }) =>
  bgImage ? (
    <BgImage
      fluid={[
        `linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3))`,
        bgImage.childImageSharp.fluid,
      ]}
    >
      {children}
    </BgImage>
  ) : (
    <Inner>{children}</Inner>
  )

const NewsletterLandingPage = ({ className, bgImage }) => {
  const [hasSubmitted, setHasSubmitted] = useState(false)

  return (
    <Wrapper className={className}>
      <Switcher bgImage={bgImage}>
        <Content>
          {!hasSubmitted ? (
            <>
              <Title>Stay Updated</Title>
              <Subtitle>
                Receive our Advisor newsletter and learn about natural
                alternatives for your optimal health.
              </Subtitle>
              <Form submitted={() => setHasSubmitted(true)} />
            </>
          ) : (
            <>
              <Title>Thank you!</Title>
              <Subtitle>Your email was registered.</Subtitle>
            </>
          )}
        </Content>
      </Switcher>
    </Wrapper>
  )
}

export default NewsletterLandingPage
