import React from 'react'
import tw, { styled } from 'twin.macro'
import { SEO, Header, ModalHandler } from '@components'
import NewFooter from './components/NewFooter'

const Wrapper = styled.div`
  ${tw`w-full`}
`

const Content = styled.section`
  ${tw`min-h-content w-full`}
`

const StyledHeader = styled(Header)``

const NewMainLayout = ({
  children,
  className,
  shouldShowNewsletterModal = true,
}) => {
  return (
    <>
      <SEO />
      <Wrapper>
        <StyledHeader />

        <Content className={className} >
          {children}
        </Content>

        <NewFooter />
      </Wrapper>

      <ModalHandler shouldShowNewsletterModal={shouldShowNewsletterModal} />
    </>
  )
}

export default NewMainLayout
