import React from 'react';

import { NewsletterModal } from '@components'

const ModalHandler = ({ shouldShowNewsletterModal }) => {
  if (shouldShowNewsletterModal) return <NewsletterModal />

  return null;
}

export default ModalHandler;
