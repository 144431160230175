import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Newsletter } from '@common/components'

const NewsletterModal = () => {
  const { desktop, mobile } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "newsletter/modal-bg-desktop.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mobile: file(relativePath: { eq: "newsletter/bg-mobile.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return <Newsletter.Modal images={{ desktop, mobile }} />
}

export default NewsletterModal
