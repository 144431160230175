const LS_KEY = 'ipInfoData'

const get = async () => {
  if (typeof window !== `undefined` && localStorage.getItem(LS_KEY)) {
    return JSON.parse(localStorage.getItem(LS_KEY))
  }

  try {
    const response = await fetch(
      `https://ipinfo.io?token=${process.env.IPINFO_TOKEN}`
    ).then((response) => response.json())

    localStorage.setItem(LS_KEY, JSON.stringify(response))
    return response
  } catch (error) {
    console.error(error)
    return {}
  }
}

const IpInfo = {
  get,
}

export default IpInfo
