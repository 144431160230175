import { navigate } from "gatsby"
import { default as React } from "react"
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
} from "react-instantsearch-dom"
import tw from 'twin.macro'

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits

  return hitCount > 0 ? (
    <div className="HitCount">
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </div>
  ) : <div className="EmptySearch">Your search did not match any items.</div>
})

const PageHit = ({ hit }) => {
  const link = `/articles/${hit.slug}`;

  const clickSearchResult = () => {
    window.dataLayer.push({
      event: 'interact-event',
      action: 'Click',
      category: `Search Result`,
      label: link,
    });

    navigate(link);
  }

  return (
    <div>
      <button tw="text-left" onClick={clickSearchResult}>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </button>
      <Snippet attribute="excerpt" hit={hit} tagName="mark" />
    </div>
  )
}

const HitsInIndex = ({ index }) => {
  return (
    <Index indexName={index.name}>
      <HitCount />
      <Hits className="Hits" hitComponent={PageHit} />
    </Index>
  )
}

const SearchResult = ({ indices, className }) => (
  <div className={className}>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
)

export default SearchResult