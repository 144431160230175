import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'

import { ipInfo } from '../../helpers'

const DefaultForm = styled.form`
  ${tw`flex w-full`}
  ${tw`md:(max-w-lg)`}
`

const DefaultInput = styled.input`
  ${tw`text-sm flex w-full border border-r-0 border-gray-300 rounded-l px-4 py-3`}
`

const DefaultButton = styled.button`
  ${tw`text-xs w-auto font-semibold uppercase tracking-wider bg-black px-4 border border-black rounded-r text-white transition-colors duration-200 whitespace-nowrap`}
  ${tw`md:(w-32)`}
  ${tw`hover:(text-black bg-gray-200)`}
`

const Form = ({
  FormComponent = DefaultForm,
  InputComponent = DefaultInput,
  ButtonComponent = DefaultButton,
  buttonText = 'Sign Up',
  submitted = () => null,
}) => {
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [email, setEmail] = useState('')

  const handleSubmit = async (e) => {
    setIsSubmiting(true)
    e.preventDefault()

    try {
      const ipInfoResponse = await ipInfo.get()
      await fetch(`${process.env.API_URL}/leads`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          country: ipInfoResponse && ipInfoResponse.country,
          region: ipInfoResponse && ipInfoResponse.region,
        }),
      })
      submitted()
    } catch (error) {
      console.error(error)
      alert('Something went wrong.')
    } finally {
      setIsSubmiting(false)
    }
  }

  return (
    <FormComponent onSubmit={handleSubmit}>
      <InputComponent
        type="text"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value.toLowerCase())}
        placeholder="Your email"
        pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
        required
      />
      <ButtonComponent disabled={isSubmiting}>{buttonText}</ButtonComponent>
    </FormComponent>
  )
}

export default Form
