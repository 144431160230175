import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import { Search as SearchIcon } from "@styled-icons/fa-solid"

export default connectSearchBox(
  ({ refine, className, setShowSearchResult, newBox = false, searchPlaceHolder = 'Search' }) => {
    if (typeof window !== `undefined`) {
      window.dataLayer = window.dataLayer || [];
    }

    const doSearch = (form) => {
      const formData = new FormData(form);

      const searchValue = formData.get('SearchInput');

      window.dataLayer.push({
        'event': 'search',
        'searchTerm': searchValue
      });

      refine(searchValue);
    }

    const submitSearch = (event) => {
      event.preventDefault();
      doSearch(event.target);
    }

    const searchButtonClick = (event) => {
      event.preventDefault();
      const form = document.getElementById('SearchInputForm');
      doSearch(form);
    }

    const onInputChange = () => {
      setShowSearchResult(false);
    }

    return (
      <form id="SearchInputForm" className={className} onSubmit={submitSearch}>
        <input
          id="SearchInput"
          name="SearchInput"
          className="SearchInput"
          type="text"
          placeholder={searchPlaceHolder}
          aria-label={searchPlaceHolder}
          onChange={onInputChange}
          autoFocus={true}
        />
        {!newBox && <SearchIcon className="SearchIcon" onClick={searchButtonClick} />}
      </form >
    );
  }
)