import styled, { css } from 'styled-components'
import SearchResult from './SearchResult'

const Popover = css`
  max-height: 70vh;
  overflow: auto;
  position: absolute;
  z-index: 50;
  right: 0px;
  top: 100%;
  margin-top: 0.5em;
  width: 80vw;
  max-width: 30em;
  box-shadow: 0px 0px 6px 0px black;
  padding: 1em;
  border-radius: 2px;
  background: rgb(39, 122, 210);
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #277ad2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dcffffb3;
    border-radius: 20px;
    border: 3px solid #277ad2;
  }
`

export default styled(SearchResult)`
  display: ${(props) => (props.show ? `block` : `none`)};
  ${Popover}

  .HitCount {
    display: flex;
    justify-content: flex-end;
    color: #fff;
  }

  .EmptySearch {
    color: #fff;
  }

  .Hits {
    ul {
      list-style: none;
      margin-left: 0;
    }
    mark {
      background-color: #ffe373;
      border-radius: 3px;
      padding-right: 4px;
      padding-left: 4px;
      font-size: 15px;
    }
    li.ais-Hits-item {
      margin-bottom: 1em;

      button {
        background-color: transparent;
        cursor: pointer;

        span {
          color: #fff;
          margin-bottom: 0.2em;
        }
      }
    }
  }
`
