import styled from "styled-components"
import SearchBox from "./SearchBox"

export default styled(SearchBox)`
  display: flex;
  align-items: center;
  margin-bottom: 0;

  .SearchInput {
    outline: none;
    box-sizing: border-box;
    border-width: 2px;
    font-size: 1em;
    transition: 100ms;
    color: #000;
    ::placeholder {
      color: #3A4444;
      text-align: center;
    }
    width: 29em;
    cursor: text;
    padding: 8px;
  }

  .SearchIcon {
    width: 1em;
    margin: 0.3em;
    color: #000;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`