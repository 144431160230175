import React from 'react'
import { Link } from 'gatsby'
import tw, { styled } from 'twin.macro'

const List = styled.ul`
  ${tw`flex flex-wrap items-center my-12 text-gray-700`}
`

const ListItem = styled.li`
  ${tw`flex items-center justify-center text-xs antialiased tracking-tight h-5 px-1 mr-1 mb-2 border rounded uppercase font-semibold`}
`

const Tags = ({ tags = [], maxItems, className }) => (
  <List className={className}>
    {tags.slice(0, maxItems).map((tag) => (
      <ListItem key={tag.id}><Link title={tag.name} to={`/tag/${tag.slug}`}>{tag.name}</Link></ListItem>
    ))}
  </List>
)

export default Tags
