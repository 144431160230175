import React from 'react'
import tw, { styled } from 'twin.macro'

import { Facebook } from '@styled-icons/evaicons-solid/Facebook'
import { Twitter } from '@styled-icons/boxicons-logos/Twitter'
import { Instagram } from '@styled-icons/boxicons-logos/Instagram'
import { Youtube } from '@styled-icons/boxicons-logos/Youtube'

const Wrapper = styled.footer`
  ${tw`flex flex-col`}
`

const WhiteBg = styled.div`
  min-height: 3.75rem;
  ${tw`flex w-full items-center bg-white`}
`

const Center = styled.div`
  ${tw`flex mx-auto w-full px-6`}
  ${tw`xl:max-w-screen-xl`}
`

const ContentBottom = styled(Center)`
  ${tw`flex flex-col w-full text-base justify-evenly items-center text-gray-900 flex-wrap py-6`}
  ${tw`md:(flex-row justify-between py-0)`}
`

const RoundLink = styled.a`
  ${tw`text-white bg-blue-600	rounded-full p-1`}
`

const WebinarFooter = ({ className }) => (
  <Wrapper className={className}>
    <WhiteBg>
      <ContentBottom>
        <p tw="max-sm:mb-6">
          © {new Date().getFullYear()} HelloMD. All rights reserved
        </p>
        <div tw="flex flex-col md:flex-row">

          <ul tw="flex items-center justify-center">
            <li>
              <RoundLink
                target="blank"
                href="//facebook.com/hellomdcompany"
                title="HelloMD's Facebook Profile"
              >
                <Facebook tw="w-6" />
              </RoundLink>
            </li>
            <li tw="ml-4">
              <RoundLink
                target="blank"
                href="//twitter.com/HelloMD_com"
                title="HelloMD's Twitter Profile"
              >
                <Twitter tw="w-6" />
              </RoundLink>
            </li>
            <li tw="ml-4">
              <RoundLink
                target="blank"
                href="https://www.instagram.com/hellomd_us"
                title="HelloMD's Instagram Profile"
              >
                <Instagram tw="w-6" />
              </RoundLink>
            </li>
            <li tw="ml-4">
              <RoundLink
                target="blank"
                href="//www.youtube.com/user/hellomdvids"
                title="HelloMD's Youtube Channel"
              >
                <Youtube tw="w-6" />
              </RoundLink>
            </li>
          </ul>
        </div>
      </ContentBottom>
    </WhiteBg>
  </Wrapper>
)

export default WebinarFooter
