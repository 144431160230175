import CommonHeader from '@common/layouts/components/Header'

const Menu = [
  {
    title: 'Health Concerns',
    children: [
      {
        title: 'Most Common',
        children: [
          {
            title: 'Anxiety',
            url: '/tag/anxiety/',
          },
          {
            title: 'Arthritis',
            url: '/tag/arthritis/',
          },
          {
            title: 'Back Pain',
            url: '/tag/back-pain/',
          },
          {
            title: 'Chronic Pain',
            url: '/tag/chronic-pain/',
          },
          {
            title: 'Depression',
            url: '/tag/depression/',
          },
          {
            title: 'Insomnia',
            url: '/tag/insomnia/',
          },
          {
            title: 'Migraines',
            url: '/tag/migraines/',
          },
        ],
      },
      {
        title: 'More Conditions',
        children: [
          {
            title: 'All Health Conditions',
            url: '/tag/health-conditions/',
          },
          {
            title: 'Alzheimer’s Disease',
            url: "/tag/alzheimer's-disease/",
          },
          {
            title: 'Asthma',
            url: '/tag/asthma/',
          },
          {
            title: 'Autism',
            url: '/tag/autism/',
          },
          {
            title: 'Cancer',
            url: '/tag/cancer/',
          },
          {
            title: 'COVID-19',
            url: '/tag/covid-19/',
          },
          {
            title: "Crohn's Disease",
            url: "/tag/crohn's-disease/",
          },
          {
            title: 'Epilepsy',
            url: '/tag/epilepsy/',
          },
          {
            title: 'Fibromyalgia',
            url: '/tag/fibromyalgia/',
          },
          {
            title: 'Herpes',
            url: '/tag/herpes/',
          },
          {
            title: 'HIV/AIDS',
            url: '/tag/hivaids/',
          },
          {
            title: 'IBD',
            url: '/tag/ibd/',
          },
          {
            title: 'IBS',
            url: '/tag/ibs/',
          },
          {
            title: 'Inflammation',
            url: '/tag/inflammation/',
          },
          {
            title: 'Lyme Disease',
            url: '/tag/lyme-disease/',
          },
          {
            title: 'Multiple Sclerosis',
            url: '/tag/multiple-sclerosis/',
          },
          {
            title: 'Nausea',
            url: '/tag/nausea/',
          },
          {
            title: 'Opiod Dependency',
            url: '/tag/opioids/',
          },
          {
            title: "Parkinson's",
            url: "/tag/parkinson's/",
          },
          {
            title: 'PMS',
            url: '/tag/pms/',
          },
          {
            title: 'PTSD',
            url: '/tag/ptsd/',
          },
          {
            title: 'Schizophrenia',
            url: '/tag/schizophrenia/',
          },
          {
            title: 'Seasonal Affective Disorder',
            url: '/tag/sad/',
          },
          {
            title: 'Seizures',
            url: '/tag/seizures/',
          },
          {
            title: 'Skin Conditions',
            url: '/tag/skin-conditions/',
          },
          {
            title: 'Sleep Apnea',
            url: '/tag/sleep-apnea/',
          },
          {
            title: 'Stress',
            url: '/tag/stress/',
          },
          {
            title: 'Weight Loss',
            url: '/tag/weight-loss/',
          },
        ],
      },
    ],
  },
  {
    title: 'Cannabis 101',
    children: [
      {
        title: 'Basic Education',
        children: [
          {
            title: 'Cannabinoids',
            url: '/tag/cannabinoids/',
          },
          {
            title: 'Cannabis 101',
            url: '/tag/cannabis-101/',
          },
          {
            title: 'CBD',
            url: '/tag/cbd/',
          },
          {
            title: 'Terpenes',
            url: '/tag/terpenes/',
          },
          {
            title: 'THC',
            url: '/tag/thc/',
          },
        ],
      },
      {
        title: 'Methods of Consumption',
        children: [
          {
            title: 'Capsules',
            url: '/tag/oil/',
          },
          {
            title: 'Concentrate/Dabs',
            url: '/tag/concentrate/',
          },
          {
            title: 'Edible',
            url: '/tag/edible/',
          },
          {
            title: 'Flower',
            url: '/tag/flower/',
          },
          {
            title: 'Tincture',
            url: '/tag/tincture/',
          },
          {
            title: 'Topical',
            url: '/tag/topical/',
          },
          {
            title: 'Vaporizer',
            url: '/tag/vaporizer/',
          },
        ],
      },
      {
        title: 'Strains',
        children: [
          {
            title: 'Strains & Products',
            url: '/tag/strains-and-products/',
          },
          {
            title: 'Hemp CBD',
            url: '/tag/hemp/',
          },
          {
            title: 'Hybrid',
            url: '/tag/hybrid/',
          },
          {
            title: 'Indica',
            url: '/tag/indica/',
          },
          {
            title: 'Sativa',
            url: '/tag/sativa/',
          },
        ],
      },
    ],
  },
  {
    title: 'Optimal Living',
    children: [
      {
        title: 'Wellness',
        children: [
          {
            title: 'Mindfulness',
            url: '/tag/mindfulness/',
          },
          {
            title: 'Pet Health',
            url: '/tag/pet-health/',
          },
          {
            title: 'Senior Living',
            url: '/tag/elderly/',
          },
          {
            title: 'Sexual Health',
            url: '/tag/sexual-health/',
          },
          {
            title: "Women's Health",
            url: "/tag/women's-health/",
          },
        ],
      },
      {
        title: 'Lifestyle',
        children: [
          {
            title: 'Cannabis in Canada',
            url: '/tag/canada/',
          },
          {
            title: 'Culture & Lifestyle',
            url: '/tag/culture-and-lifestyle/',
          },
          {
            title: 'Industry Spotlight',
            url: '/tag/industry-spotlight/',
          },
          {
            title: 'Legalization',
            url: '/tag/legalization/',
          },
          {
            title: 'News & Events',
            url: '/tag/news-and-events/',
          },
          {
            title: 'Recipes & DIY',
            url: '/tag/recipes-and-diy/',
          },
          {
            title: 'Science & Research',
            url: '/tag/science-and-research/',
          },
        ],
      },
    ],
  },
  {
    title: 'Get Your Medical Document',
    type: CommonHeader.TYPES.BUTTON,
    url: '/get-started',
  },
]

export default Menu
