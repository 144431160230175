import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import tw, { css } from 'twin.macro'
import { Search } from "@common/components"

const TYPES = {
  BUTTON: 'Button',
}

const Wrapper = styled.header`
  ${tw`relative flex w-full h-16 px-6 bg-white self-start shadow-none items-center justify-between border-b border-gray-200 z-20`}
`

const Logo = styled.h2`
  ${tw`w-32 h-10 bg-no-repeat bg-left text-primary`}
  ${css`
    background-image: url('/logo.svg');
    text-indent: -9999px;
  `}
`

const Nav = styled.nav`
  top: 100%;
  ${tw`left-0 right-0 hidden bg-white w-full border-b border-t border-gray-200 z-10`}
  ${tw`max-md:(absolute)`}
  ${tw`md:(top-auto h-full w-auto border-none block)`}
  ${({ isMobileMenuOpened }) => isMobileMenuOpened && tw`block`}
`

const NavList = styled.ul`
  ${tw`flex flex-col h-full p-6`}
  ${tw`md:(flex-row items-center p-0)`}
`

const NavListItemSearch = styled.li`
  ${tw`py-1`}
  ${tw`md:(flex p-0 h-full items-center)`}
  ${css`
    &:hover > div {
      ${tw`visible opacity-100`}
    }
  `}
`

const NavListItem = styled.li`
  ${tw`py-1`}
  ${tw`md:(flex p-0 h-full)`}
  ${css`
    &:hover > div {
      ${tw`visible opacity-100`}
    }
  `}
`

const SubmenuWrapper = styled.div`
  ${tw`md:(absolute inset-x-0 transition-all duration-150)`}
  ${tw`md:(invisible opacity-0 mt-header)`}
  ${tw`lg:(flex flex-row-reverse)`}
`

const SubmenuList = styled.ul`
  ${tw`hidden ml-4 mt-2`}
  ${tw`md:(flex min-w-148 mx-6 my-0 p-6 bg-gray-800 text-white divide-x divide-gray-400)`}
  ${({ menuOpened: { menu }, identifier }) =>
    menu === identifier && tw`max-md:(block)`}
`

const navItemBaseStyles = css`
  ${tw`flex items-center h-8 w-full text-xs uppercase font-semibold tracking-wider  hover:opacity-75`}
  ${tw`md:(flex items-center px-4 h-full)`}
`

const NavListAnchor = styled(Link)`
  ${navItemBaseStyles}
  ${tw`cursor-pointer`}
`
const NavListTitle = styled.p`
  ${navItemBaseStyles}
`

const NavListButton = styled.span`
  background-color: #ffe373;
  ${tw`flex items-center justify-center text-center text-xs font-semibold p-1 px-2 rounded text-gray-800 tracking-wider uppercase border border-transparent outline-none transition-colors duration-200 mt-2 mb-4 cursor-default`}
  ${tw`hover:(opacity-75)`}
  ${tw`md:(ml-4 my-auto)`}
`

const NavListButtonClickable = styled(Link)`
  background-color: #ffe373;
  ${tw`flex items-center justify-center text-center text-xs font-semibold p-1 px-2 rounded text-gray-800 tracking-wider uppercase border border-transparent outline-none transition-colors duration-200 mt-2 mb-4 cursor-pointer`}
  ${tw`hover:(opacity-75)`}
  ${tw`md:(ml-4 my-auto)`}
`

const SubmenuListItem = styled.li`
  ${tw`md:(flex-1 pl-6 first:pl-0 text-xs)`}
  ${tw`lg:(max-w-xs min-w-80)`}
`

const SubmenuListItemTitle = styled.p`
  ${tw`text-xs uppercase font-semibold tracking-wider mb-4 cursor-pointer hover:opacity-75`}
  ${tw`md:(cursor-default hover:opacity-100)`}
`

const SubList = styled.ul`
  ${tw`hidden ml-4 mt-2 mb-4`}
  ${tw`md:(block mx-0 my-0)`}
  ${({ menuOpened: { menu, submenu }, identifier }) =>
    `${menu}:${submenu}` === identifier && tw`block`}
`

const SubListItem = styled.li`
  ${tw`mb-3 last:mb-0 text-xs font-semibold tracking-wider`}
  ${tw`md:(font-normal mb-2)`}
`

const MobileNavTrigger = styled.div`
  ${tw`relative w-full bg-black my-1`}
  ${css`
    height: 2px;
    &:before,
    &:after {
      content: '';
      top: -6px;
      height: 2px;
      ${tw`absolute w-full bg-black`}
    }
    &:after {
      ${tw`top-auto`}
      bottom: -6px;
    }
  `}
`

const Header = ({ site, className, BelowCTA = () => null, Menu }) => {
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false)
  const [menuOpened, setMenuOpened] = useState({})

  const handleMenus = (identifiers) => {
    const [menu, submenu] = identifiers.split(':')

    // closing menu if already opened
    if (menu === menuOpened.menu && !submenu) {
      setMenuOpened({ menu: '', submenu: '' })
      return
    }

    setMenuOpened({ menu, submenu })
  }


  const {
    searchIndex
  } = site.siteMetadata;

  return (
    <Wrapper className={className}>
      <Link to="/" title="HelloMD">
        <Logo>HelloMD</Logo>
      </Link>
      <Nav isMobileMenuOpened={isMobileMenuOpened}>
        <NavList>
          <NavListItemSearch>
            <Search indices={[{ name: searchIndex }]} />
          </NavListItemSearch>
          {Menu.map(({ title, type, url, children }, i) => (
            <NavListItem key={title}>
              {!children ? (
                type && type === TYPES.BUTTON ? (
                  <NavListButtonClickable to={url} title={title}>
                    {title}
                  </NavListButtonClickable>
                ) : (
                  <NavListAnchor to={url}>{title}</NavListAnchor>
                )
              ) : (
                <>
                  {type && type === TYPES.BUTTON ? (
                    <NavListButton onClick={() => handleMenus(`menu${i}`)}>
                      {title}
                    </NavListButton>
                  ) : (
                    <NavListTitle onClick={() => handleMenus(`menu${i}`)}>
                      {title}
                    </NavListTitle>
                  )}
                  <SubmenuWrapper>
                    <SubmenuList
                      identifier={`menu${i}`}
                      menuOpened={menuOpened}
                    >
                      {children.map(({ title, children }, j) => (
                        <SubmenuListItem key={`submenu:${j}`}>
                          <SubmenuListItemTitle
                            onClick={() => handleMenus(`menu${i}:submenu${j}`)}
                          >
                            {title}
                          </SubmenuListItemTitle>
                          <SubList
                            identifier={`menu${i}:submenu${j}`}
                            menuOpened={menuOpened}
                          >
                            {children.map(({ title, url }, l) => (
                              <SubListItem key={`sublist:${l}`}>
                                <a href={url} title={title}>
                                  {title}
                                </a>
                              </SubListItem>
                            ))}
                          </SubList>
                        </SubmenuListItem>
                      ))}
                    </SubmenuList>
                  </SubmenuWrapper>
                </>
              )}
            </NavListItem>
          ))}
        </NavList>
      </Nav>

      <BelowCTA />

      <div
        tw="cursor-pointer hover:opacity-75 w-10 p-2 md:hidden"
        onClick={() =>
          setIsMobileMenuOpened((isMobileMenuOpened) => !isMobileMenuOpened)
        }
      >
        <MobileNavTrigger />
      </div>
    </Wrapper>
  )
}

Header.TYPES = TYPES

export default Header
