import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'

import { ipInfo } from '../../helpers'

import Buttons from "../Buttons"

const DefaultForm = styled.form`
  ${tw`flex w-full`}
  ${tw`md:(max-w-lg)`}
`

const DefaultInput = styled.input`
  ${tw`text-sm flex w-full border border-r-0 border-gray-300 rounded-l px-4 py-3`}
`

const StyledButton = styled(Buttons.Primary)`
  ${tw`justify-center cursor-pointer mb-2 w-full`}
`

const NewForm = ({
  FormComponent = DefaultForm,
  InputComponent = DefaultInput,
  buttonText = 'Sign Up',
  submitted = () => null,
}) => {
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [email, setEmail] = useState('')
  const [zipcode, setZipcode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [comments, setComments] = useState('')

  const handleSubmit = async (e) => {
    setIsSubmiting(true)
    e.preventDefault()

    try {
      const ipInfoResponse = await ipInfo.get()
      await fetch(`${process.env.API_URL}/leads`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          zipcode,
          phoneNumber,
          comments,
          country: ipInfoResponse && ipInfoResponse.country,
          region: ipInfoResponse && ipInfoResponse.region,
        }),
      })
      submitted()
    } catch (error) {
      console.error(error)
      alert('Something went wrong.')
    } finally {
      setIsSubmiting(false)
    }
  }

  return (
    <FormComponent onSubmit={handleSubmit}>
      <InputComponent
        type="text"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value.toLowerCase())}
        placeholder="Your email"
        pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
        required
      />
      <InputComponent
        type="text"
        name="zipcode"
        value={zipcode}
        onChange={(e) => setZipcode(e.target.value.toLowerCase())}
        placeholder="Your Zipcode (for localized info)"
      />
      <InputComponent
        type="text"
        name="phoneNumber"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value.toLowerCase())}
        placeholder="Your Phone Number"
      />
      <InputComponent
        type="text"
        name="comments"
        value={comments}
        onChange={(e) => setComments(e.target.value.toLowerCase())}
        placeholder="Any Comments?"
      />
      <button type="submit">
        <StyledButton disabled={isSubmiting}>{buttonText}</StyledButton>
      </button>
    </FormComponent>
  )
}

export default NewForm
