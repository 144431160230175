import React, { useEffect, useState } from 'react'
import Img from 'gatsby-image'
import tw, { styled } from 'twin.macro'
import isBot from 'isbot'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'

import NewForm from './NewForm'

const LS_KEY = 'newsletterModalWasDismissed'

const Wrapper = styled.div`
  ${tw`absolute inset-0 flex items-center justify-center`}
`

const Behind = styled.div`
  ${tw`absolute inset-0 z-50 w-full h-full opacity-50 bg-black`}
`

const Image = styled(Img)`
  ${tw`md:(w-full h-full p-4)`}
`

const Content = styled.div`
  ${tw`relative flex m-2 flex-col-reverse bg-white z-[60] md:(flex-row w-4/5 h-auto) 2xl:(w-1/2)`}
`

const Inner = styled.div`
  ${tw`flex flex-col pt-4 pb-2 px-8 md:(justify-center)`}
`

const StyledForm = styled.form`
  ${tw`flex flex-col w-full`}
`

const StyledInput = styled.input`
  ${tw`text-sm flex w-full border border-gray-400 mb-1 p-1 placeholder-black md:(p-2)`}
`

const NewsletterNewModal = ({ images }) => {
  const { desktop, mobile } = images

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      !isBot(window.navigator.userAgent) &&
      localStorage.getItem(LS_KEY) === null
    ) {
      setTimeout(() => {
        setIsVisible(true)
      }, 10 * 1000)
    }
  }, [])

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add('no-scroll')
      return
    }
    document.body.classList.remove('no-scroll')
  }, [isVisible])

  const closeModal = () => {
    setIsVisible(false)
    localStorage.setItem(LS_KEY, true)
  }

  const sources = [
    mobile.childImageSharp.fluid,
    {
      ...desktop.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return isVisible ? (
    <Wrapper>
      <Behind />
      <Content>
        <Image fluid={sources} />
        <Inner>
          <span
            onClick={closeModal}
            tw="absolute top-2 right-2 items-center justify-center w-8 h-8 text-black text-2xl leading-none cursor-pointer"
          >
            <CloseOutline tw="w-8 float-right	" />
          </span>
          <h1 tw="text-lg leading-tight font-bold mb-2 text-primary md:(text-3xl)">
            The perfect dose of cannabis advice right to your inbox
          </h1>
          <h4 tw="text-xs mb-3 md:(text-lg)">
            Sign-up for news, deals, and more!
          </h4>

          <NewForm
            FormComponent={StyledForm}
            InputComponent={StyledInput}
            submitted={closeModal}
            buttonText={'SUBSCRIBE NOW'}
          />
          <h4 tw="text-extra-xs text-center px-10 md:(text-sm)">
            By signing up for Perfect Dose, you agree to our Terms of Service and Privacy Policy.
          </h4>
        </Inner>
      </Content>
    </Wrapper >
  ) : null
}

export default NewsletterNewModal
