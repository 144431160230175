import React from 'react'
import { a } from 'gatsby'
import tw, { styled } from 'twin.macro'

import { Facebook } from '@styled-icons/evaicons-solid/Facebook'
import { Twitter } from '@styled-icons/boxicons-logos/Twitter'
import { Instagram } from '@styled-icons/boxicons-logos/Instagram'
import { Youtube } from '@styled-icons/boxicons-logos/Youtube'

import Logo from '../../images/logo.inline.svg'
import CAFlag from '../../images/flags/ca.inline.svg'
import USFlag from '../../images/flags/us.inline.svg'

const Wrapper = styled.footer`
  ${tw`flex flex-col`}
`

const BlackBg = styled.div`
  min-height: 3.75rem;
  ${tw`flex w-full items-center bg-black`}
`

const BlueBg = styled(BlackBg)`
  ${tw`bg-primary py-9`}
`

const Center = styled.div`
  ${tw`flex mx-auto w-full px-6`}
  ${tw`xl:max-w-screen-xl`}
`

const ContentTop = styled(Center)`
  ${tw`flex flex-col w-full text-base justify-between text-white flex-wrap py-8`}
  ${tw`md:(flex-row justify-between)`}
`

const ContentBottom = styled(Center)`
  ${tw`flex flex-col w-full text-xs justify-evenly items-center text-white flex-wrap py-6`}
  ${tw`md:(flex-row justify-between py-0)`}
`

const ListItem = styled.li`
  ${tw`my-1`}
`

const NewFooter = ({ className }) => (
  <Wrapper className={className}>
    <BlueBg>
      <ContentTop>
        <div>
          <div tw="flex mb-1 items-center">
            <Logo tw="w-80 h-24 text-white mr-4" />
          </div>
        </div>
        <div tw="flex flex-col mt-10 md:(flex flex-row mt-0)">
          <div>
            <h3 tw="uppercase font-semibold tracking-wider mb-2">
              Ask
            </h3>
            <ul>
              <ListItem>
                <a href="/questions-and-answers" title="Q&amp;As">
                  Q&amp;As
                </a>
              </ListItem>
              <ListItem>
                <a href="/about-us" title="About Us">
                  About Us
                </a>
              </ListItem>
              <ListItem>
                <a href="mailto:support@hellomd.com">Contact Us</a>
              </ListItem>
            </ul>
          </div>
          <div tw="mt-8 md:(mt-0 ml-16)">
            <h3 tw="uppercase font-semibold tracking-wider mb-2">
              Explore
            </h3>
            <ul>
              <ListItem>
                <a href="/tag/cannabis-101" title="Cannabis 101">
                  Cannabis 101
                </a>
              </ListItem>
              <ListItem>
                <a href="/strains" title="Strains">
                  Strains
                </a>
              </ListItem>
              <ListItem>
                <a href="/latest-articles" title="Latest Articles">
                  Latest Articles
                </a>
              </ListItem>
            </ul>
          </div>
          <div tw="mt-8 md:(mt-0 ml-16)">
            <a href="/get-started" title="Medical Marijuana Card">
              <h3 tw="uppercase font-semibold tracking-wider mb-2">
                GET YOUR MEDICAL MJ CARD
              </h3>
            </a>
            <ul>
              <ListItem>
                <a href="/get-started/illinois" title="Medical Marijuana Card IL">
                  <h4>Illinois</h4>
                </a>
              </ListItem>
              <ListItem>
                <a href="/get-started/louisiana" title="Medical Marijuana Card LA">
                  <h4>Louisiana</h4>
                </a>
              </ListItem>
              <ListItem>
                <a href="/get-started/missouri" title="Medical Marijuana Card MO">
                  <h4>Missouri</h4>
                </a>
              </ListItem>
              <ListItem>
                <a href="/get-started/new-york" title="Medical Marijuana Card NY">
                  <h4>New York</h4>
                </a>
              </ListItem>
              <ListItem>
                <a href="/get-started/ohio" title="Medical Marijuana Card OH">
                  <h4>Ohio</h4>
                </a>
              </ListItem>
              <ListItem>
                <a href="/get-started/pennsylvania" title="Medical Marijuana Card PA">
                  <h4>Pennsylvania</h4>
                </a>
              </ListItem>
            </ul>
          </div>
          <div tw="mt-8 md:(mt-0 ml-16)">
            <h3 tw="uppercase font-semibold tracking-wider mb-2">
              LEGAL STUFF
            </h3>
            <ul>
              <ListItem>
                <a href="/privacy-policy" title="Privacy Policy">
                  Privacy Policy
                </a>
              </ListItem>
              <ListItem>
                <a href="/terms-of-use/" title="Terms of Use">
                  Terms of Use
                </a>
              </ListItem>
            </ul>
          </div>
        </div>
      </ContentTop>
    </BlueBg>
    <BlackBg>
      <ContentBottom>
        <p tw="max-sm:mb-6">
          © {new Date().getFullYear()} HelloMD. All rights reserved
        </p>
        <div tw="flex flex-col md:flex-row">
          <ul tw="flex items-center justify-center items-center max-sm:mb-6">
            <li tw="mr-4">
              <a tw="flex justify-center items-center" href="//hellomd.com">
                <USFlag tw="w-8 h-6 mr-2" />
                <span>United States</span>
              </a>
            </li>
            <li tw="mr-4">
              <a tw="flex justify-center items-center" href="//hellomd.ca">
                <CAFlag tw="w-8 h-6 mr-2" />
                <span>Canada</span>
              </a>
            </li>
          </ul>

          <ul tw="flex items-center justify-center">
            <li>
              <a
                target="blank"
                href="//facebook.com/hellomdcompany"
                title="HelloMD's Facebook Profile"
              >
                <Facebook tw="w-4" />
              </a>
            </li>
            <li tw="ml-4">
              <a
                target="blank"
                href="//twitter.com/HelloMD_com"
                title="HelloMD's Twitter Profile"
              >
                <Twitter tw="w-4" />
              </a>
            </li>
            <li tw="ml-4">
              <a
                target="blank"
                href="https://www.instagram.com/hellomd_us"
                title="HelloMD's Instagram Profile"
              >
                <Instagram tw="w-4" />
              </a>
            </li>
            <li tw="ml-4">
              <a
                target="blank"
                href="//www.youtube.com/user/hellomdvids"
                title="HelloMD's Youtube Channel"
              >
                <Youtube tw="w-5" />
              </a>
            </li>
          </ul>
        </div>
      </ContentBottom>
    </BlackBg>
  </Wrapper>
)

export default NewFooter
