import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { SEO as CommonSEO } from '@common/components'

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

const SEO = (props) => {
  const { site } = useStaticQuery(query)

  return <CommonSEO site={site} {...props} />
}

export default SEO
