import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

const Form = styled.form`
  ${tw`flex flex-col mt-4`}
`

const Input = styled.input`
  ${tw`my-2 !p-2 placeholder-black`}
`

const RegistrationForm = ({ subdomain, buttonDivStyle, buttonStyle }) => {
  const mixpanel = useMixpanel()

  const [partnerId, setPartnerId] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const getPartnerInfo = async () => {
    const getPartnerInfoResponse = await fetch(
      `https://hellomd-api.herokuapp.com/partners/subdomain/${subdomain}`
    )

    const partnerInfo = await getPartnerInfoResponse.json()
    setPartnerId(partnerInfo._id)
  }

  useEffect(() => {
    getPartnerInfo()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    await fetch(
      `${process.env.FUNNEL_API}/partners/${partnerId}/users?next=%2Fget-started`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          partnerOrigin: subdomain,
        }),
      }
    )

    mixpanel.track('Next_Click', { Funnel: subdomain })

    window.dataLayer.push({
      event: 'interact-event',
      action: 'Click',
      category: `MJCard - ${subdomain}`,
      label: 'Submit',
    })

    setIsFormSubmitted(true)
  }

  const handleSubmitResend = async (e) => {
    e.preventDefault()

    await fetch(
      `${process.env.FUNNEL_API}/partners/${partnerId}/user/confirmation-emails?next=%2Fget-started`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          partnerOrigin: subdomain,
        }),
      }
    )
  }

  if (isFormSubmitted) {
    return (
      <Form onSubmit={handleSubmitResend}>
        <h1>&#128233; Check your email.</h1>
        <div tw="mb-12">
          We sent you an email with a link you’ll need to click to continue on
          and schedule your appointment.
        </div>
        <span tw="text-center mb-4 text-base text-primary font-bold">
          Not seeing it?
        </span>
        <div className={buttonDivStyle}>
          <input
            tw="w-full"
            type="submit"
            className={buttonStyle}
            value="RESEND EMAIL"
            style={{ cursor: 'pointer' }}
          />
        </div>
      </Form>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <h1 tw="mb-8">&#128075; Get started here.</h1>
      <Input
        type="email"
        placeholder="* Enter your Email"
        required
        onChange={(e) => setEmail(e.target.value.toLowerCase())}
      />
      <Input
        tw="mb-8"
        type="password"
        placeholder="* Create your password (min 8 characters)"
        required
        onChange={(e) => setPassword(e.target.value)}
      />
      <div className={buttonDivStyle}>
        <input
          tw="w-full"
          type="submit"
          className={buttonStyle}
          value="NEXT"
          style={{ cursor: 'pointer' }}
        />
      </div>
    </Form>
  )
}

export default RegistrationForm
