import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import CommonHeader from '@common/layouts/components/Header'
import Menu from './Menu'

const query = graphql`
  query {
    site {
      siteMetadata {
        searchIndex
      }
    }
  }
`

const Header = () => {
  const { site } = useStaticQuery(query)

  return <CommonHeader site={site} Menu={Menu} />
}

export default Header

