import algoliasearch from "algoliasearch/lite"
import { createRef, default as React, useState, useMemo } from "react"
import { InstantSearch, Configure } from "react-instantsearch-dom"
import { ThemeProvider } from "styled-components"
import StyledSearchBox from "./StyledSearchBox"
import NewStyledSearchBox from "./NewStyledSearchBox"
import StyledSearchResult from "./StyledSearchResult"
import StyledSearchRoot from "./StyledSearchRoot"
import useClickOutside from "./UseClickOutside"

const theme = {
  foreground: "#050505",
  background: "white",
  faded: "#888",
}

export default function Search({ indices, newBox = false, searchPlaceHolder }) {
  const rootRef = createRef()
  const [query, setQuery] = useState()
  const [showSearchResult, setShowSearchResult] = useState(false)
  const searchClient = useMemo(
    () => {
      const algoliaClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY);
      return {
        ...algoliaClient,
        search(requests) {
          if (requests.every(({ params }) => !params.query)) {
            return Promise.resolve({
              results: requests.map(() => ({
                hits: [],
                nbHits: 0,
                nbPages: 0,
                page: 0,
                processingTimeMS: 0,
              })),
            });
          }

          return algoliaClient.search(requests);
        },
      }
    },
    []
  )

  useClickOutside(rootRef, () => setShowSearchResult(false))

  return (
    <ThemeProvider theme={theme}>
      <StyledSearchRoot ref={rootRef}>
        <InstantSearch
          searchClient={searchClient}
          indexName={indices[0].name}
          onSearchStateChange={({ query }) => { setQuery(query); setShowSearchResult(true); }}
        >
          <Configure hitsPerPage={10} />
          {newBox ? <NewStyledSearchBox setShowSearchResult={setShowSearchResult} searchPlaceHolder={searchPlaceHolder} newBox={newBox} /> : <StyledSearchBox setShowSearchResult={setShowSearchResult} />}
          <StyledSearchResult
            show={query && query.length > 0 && showSearchResult}
            indices={indices}
          />
        </InstantSearch>
      </StyledSearchRoot>
    </ThemeProvider>
  )
}