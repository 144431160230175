import React, { useEffect, useState } from 'react'
import BackgroundImage from 'gatsby-background-image'
import tw, { styled } from 'twin.macro'
import isBot from 'isbot'

import Inner from './Inner'

const LS_KEY = 'newsletterModalWasDismissed'

const Wrapper = styled.div`
  ${tw`absolute inset-0 flex items-center justify-center`}
`

const Behind = styled.div`
  ${tw`absolute inset-0 z-30 w-full h-full opacity-50 bg-black`}
`

const Background = styled(BackgroundImage)`
  ${tw`flex bg-white z-30 p-6`}
  ${tw`max-md:(w-full h-full p-4)`}
  width: 820px;
  height: 480px;
`

const Content = styled.div`
  ${tw`flex flex-col text-center mt-8 md:(w-1/2 text-left justify-center mt-0)`}
`

const NewsletterModal = ({ images }) => {
  const { desktop, mobile } = images

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      !isBot(window.navigator.userAgent) &&
      localStorage.getItem(LS_KEY) === null
    ) {
      setTimeout(() => {
        setIsVisible(true)
      }, 10 * 1000)
    }
  }, [])

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add('no-scroll')
      return
    }
    document.body.classList.remove('no-scroll')
  }, [isVisible])

  const closeModal = () => {
    setIsVisible(false)
    localStorage.setItem(LS_KEY, true)
  }

  const sources = [
    mobile.childImageSharp.fluid,
    {
      ...desktop.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return isVisible ? (
    <Wrapper>
      <Behind />
      <Background fluid={sources}>
        <Content>
          <Inner dismiss={closeModal} />
        </Content>
      </Background>
    </Wrapper>
  ) : null
}

export default NewsletterModal
