import React from 'react'
import tw, { styled } from 'twin.macro'

import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'

import Form from './Form'

const Inner = styled.div`
  ${tw`flex flex-col text-center mt-8 md:(text-left justify-center mt-0)`}
`

const StyledForm = styled.form`
  ${tw`flex flex-col w-full`}
`

const StyledInput = styled.input`
  ${tw`text-sm flex w-full border border-gray-300 rounded px-4 py-3 mb-4`}
`

const StyledButton = styled.button`
  ${tw`text-xs w-full h-12 font-semibold uppercase tracking-wider bg-secondary px-4 mb-4 rounded-r text-black transition-colors duration-200 whitespace-nowrap`}
  ${tw`hover:(text-white bg-black)`}
`

const StyledCancelButton = styled.button`
  ${tw`text-xs w-full h-12 font-semibold uppercase tracking-wider bg-gray-600 px-4 mb-4 rounded-r text-white transition-colors duration-200 whitespace-nowrap`}
  ${tw`hover:(text-white bg-black)`}
`

const NewsletterInner = ({ dismiss = () => null }) => {
  return (
    <Inner>
      <h1 tw="text-2halfxl uppercase font-bold mb-4">
        Get Good Cannabis Advice
      </h1>
      <h4 tw="text-2xl font-semibold mb-3">
        Learn about cannabis, wellness strategies and products.
      </h4>
      <p tw="mb-6">Exclusive offers, webinars and life hacks galore!</p>

      <Form
        FormComponent={StyledForm}
        InputComponent={StyledInput}
        ButtonComponent={StyledButton}
        submitted={dismiss}
        buttonText={'YES, SIGN ME UP'}
      />
      <StyledCancelButton
        onClick={dismiss}
      >
        No thanks, I’m not interested.
      </StyledCancelButton>
      <span
        onClick={dismiss}
        tw="absolute flex top-4 right-4 items-center justify-center w-8 h-8 text-black text-2xl leading-none cursor-pointer bg-gray-100"
      >
        <CloseOutline tw="w-8" />
      </span>
    </Inner>
  )
}

export default NewsletterInner
