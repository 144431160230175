import React from 'react'
import Img from 'gatsby-image'
import tw, { styled } from 'twin.macro'

const Wrapper = styled.div`
  ${tw`flex items-center text-xs uppercase text-gray-600 font-semibold mb-5`}
`

const AuthorImage = styled.div`
  ${tw`w-10 h-10 mr-3`}
`

const AuthorImageInner = styled.div`
  ${tw`w-full h-full rounded-full overflow-hidden`}
`

const Author = ({ author, createdAt, timeToRead }) => (
  <Wrapper
    itemProp="author publisher"
    itemScope
    itemType="http://schema.org/Organization"
  >
    {author && (
      <>
        {author.avatar && (
          <AuthorImage>
            <AuthorImageInner>
              <Img
                alt="author"
                itemProp="image logo"
                tw="w-full h-full"
                fixed={author.avatar.childImageSharp.fixed}
              />
            </AuthorImageInner>
          </AuthorImage>
        )}
        <span>by</span>
        <span tw="text-black text-center ml-1" itemProp="name">
          {author.name}
        </span>
        <span tw="mx-3">•</span>
      </>
    )}
    {timeToRead && (
      <>
        <span tw="text-center">{timeToRead} minutes</span>
      </>
    )}
  </Wrapper>
)

export default Author
