import styled from "styled-components"
import SearchBox from "./SearchBox"

export default styled(SearchBox)`
  display: flex;
  align-items: center;
  margin-bottom: 0;

  .SearchInput {
    outline: none;
    border: "auto";
    font-size: 1em;
    transition: 100ms;
    border-radius: 2px;
    color: #000;
    ::placeholder {
      color: ${({ theme }) => theme.faded};
    }
    width: 10em;
    background: ${({ theme }) => theme.background};
    cursor: text;
    margin-left: -1.6em;
    padding-left: 1.6em;
  }

  .SearchIcon {
    width: 1em;
    margin: 0.3em;
    color: #000;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`