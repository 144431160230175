import React from 'react'
import { a } from 'gatsby'
import tw, { styled } from 'twin.macro'

import { Facebook } from '@styled-icons/evaicons-solid/Facebook'
import { Twitter } from '@styled-icons/boxicons-logos/Twitter'
import { Instagram } from '@styled-icons/boxicons-logos/Instagram'
import { Youtube } from '@styled-icons/boxicons-logos/Youtube'

import Logo from '../../images/logo.inline.svg'
import CAFlag from '../../images/flags/ca.inline.svg'
import USFlag from '../../images/flags/us.inline.svg'

const Wrapper = styled.footer`
  ${tw`flex flex-col`}
`

const BlackBg = styled.div`
  min-height: 3.75rem;
  ${tw`flex w-full items-center bg-black`}
`

const GrayBg = styled(BlackBg)`
  ${tw`bg-gray-900`}
`

const Center = styled.div`
  ${tw`flex mx-auto w-full px-6`}
  ${tw`xl:max-w-screen-xl`}
`

const ContentTop = styled(Center)`
  ${tw`flex flex-col w-full text-xs justify-between text-white flex-wrap py-8`}
  ${tw`md:(flex-row justify-between)`}
`

const ContentBottom = styled(Center)`
  ${tw`flex flex-col w-full text-xs justify-evenly items-center text-white flex-wrap py-6`}
  ${tw`md:(flex-row justify-between py-0)`}
`

const ListItem = styled.li`
  ${tw`my-1`}
`

const Footer = ({ className }) => (
  <Wrapper className={className}>
    <GrayBg>
      <ContentTop>
        <div>
          <div tw="flex mb-1 items-center">
            <Logo tw="w-32 h-12 text-white mr-4" />
          </div>
          <p tw="text-sm">Your trusted source for MJ information.</p>
        </div>
        <div tw="flex mt-10 md:(mt-0)">
          <div>
            <h3 tw="uppercase font-semibold tracking-wider mb-2">
              Useful Links
            </h3>
            <ul>
              <ListItem>
                <a href="/about-us/" title="About Us">
                  About Us
                </a>
              </ListItem>
              <ListItem>
                <a href="/newsletter/" title="Newsletter">
                  Newsletter
                </a>
              </ListItem>
              <ListItem>
                <a href="/privacy-policy/" title="Privacy Policy">
                  Privacy Policy
                </a>
              </ListItem>
              <ListItem>
                <a href="/terms-of-use/" title="Terms of Use">
                  Terms of Use
                </a>
              </ListItem>
            </ul>
          </div>
          <div tw="ml-12 md:(ml-32)">
            <h3 tw="uppercase font-semibold tracking-wider mb-2">Contact Us</h3>
            <p>
              <a href="mailto:support@hellomd.com">support@hellomd.com</a>{' '}
            </p>
          </div>
        </div>
      </ContentTop>
    </GrayBg>
    <BlackBg>
      <ContentBottom>
        <p tw="max-sm:mb-6">
          © {new Date().getFullYear()} HelloMD. All rights reserved
        </p>
        <div tw="flex flex-col md:flex-row">
          <ul tw="flex items-center justify-center items-center max-sm:mb-6">
            <li tw="mr-4">
              <a tw="flex justify-center items-center" href="//hellomd.com">
                <USFlag tw="w-8 h-6 mr-2" />
                <span>United States</span>
              </a>
            </li>
            <li tw="mr-4">
              <a tw="flex justify-center items-center" href="//hellomd.ca">
                <CAFlag tw="w-8 h-6 mr-2" />
                <span>Canada</span>
              </a>
            </li>
          </ul>

          <ul tw="flex items-center justify-center">
            <li>
              <a
                target="blank"
                href="//facebook.com/hellomdcompany"
                title="HelloMD's Facebook Profile"
              >
                <Facebook tw="w-4" />
              </a>
            </li>
            <li tw="ml-4">
              <a
                target="blank"
                href="//twitter.com/HelloMD_com"
                title="HelloMD's Twitter Profile"
              >
                <Twitter tw="w-4" />
              </a>
            </li>
            <li tw="ml-4">
              <a
                target="blank"
                href="https://www.instagram.com/hellomd_us"
                title="HelloMD's Instagram Profile"
              >
                <Instagram tw="w-4" />
              </a>
            </li>
            <li tw="ml-4">
              <a
                target="blank"
                href="//www.youtube.com/user/hellomdvids"
                title="HelloMD's Youtube Channel"
              >
                <Youtube tw="w-5" />
              </a>
            </li>
          </ul>
        </div>
      </ContentBottom>
    </BlackBg>
  </Wrapper>
)

export default Footer
