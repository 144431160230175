import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import tw, { styled } from 'twin.macro'

import { ipInfo } from '@common/helpers'

const VISIBLE_IN_REGIONS = ['São Paulo', 'New York', 'Illinois', 'Pennsylvania', 'Missouri']

const Wrapper = styled.div`
  background-color: #94c4b5;
  ${tw`flex flex-col w-full mb-10 p-4 justify-center items-center -mt-6`}
  ${tw`md:(px-6 flex-row justify-between)`}
`

const Text = styled.p`
  ${tw`text-lg font-semibold text-center text-white mb-4`}
  ${tw`md:(text-left mb-0)`}
`

const Button = styled(Link)`
  background-color: #ffe373;
  ${tw`flex items-center justify-center text-sm font-semibold w-40 h-10 rounded text-gray-800 tracking-wider uppercase border border-transparent outline-none transition-colors duration-200 leading-none`}
  ${tw`hover:(text-black bg-white border-white opacity-100)`}
`

const LineBreak = styled.br`
  ${tw`max-md:(hidden)`}
`

const CTA = ({ isVisible, setIsVisible, doNotFetch = false }) => {
  useEffect(() => {
    async function fetchData() {
      const ipInfoResponse = await ipInfo.get()

      if (
        isVisible ||
        (ipInfoResponse && VISIBLE_IN_REGIONS.includes(ipInfoResponse.region))
      ) {
        setIsVisible(true)
      }
    }

    if (!doNotFetch) {
      fetchData()
    }
  }, [isVisible, setIsVisible, doNotFetch])

  return isVisible ? (
    <Wrapper>
      <Text>
        Get your medical cannabis document online <LineBreak />
        with a licensed healthcare practitioner.
      </Text>
      <Button to="/get-started">Get Started</Button>
    </Wrapper>
  ) : null
}

export default CTA
