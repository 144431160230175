import React from 'react'
import { Link } from 'gatsby'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'

const Wrapper = styled.section`
  ${tw`flex flex-col flex-shrink px-8 pb-8`}
  ${tw`sm:(h-180 sticky top-0 px-0 overflow-hidden)`}
`

const List = styled.ul``

const ListItem = styled.li`
  ${tw`w-full`}
  ${tw`md:(w-3/4)`}
`

const Title = styled.h3`
  ${tw`mb-2 font-semibold`}
`

const Related = ({ relatedArticles = [] }) => {
  if (relatedArticles.length === 0) return null

  return (
    <Wrapper>
      <Title>Related Articles</Title>
      <List>
        {relatedArticles.map((article) => (
          <ListItem key={article.id}>
            <Link
              to={`/articles/${article.slug}`}
              title={`Article: ${article.title}`}
              tw="block overflow-hidden cursor-pointer"
            >
              {article.image && (
                <Img
                  tw="w-full mb-2"
                  fluid={{
                    ...article.image.childImageSharp.fluid,
                    aspectRatio: 380 / 255,
                  }}
                  alt={article.title}
                />
              )}
              <h2 tw="text-base text-gray-900 tracking-tight leading-6 mb-2">
                {article.title}
              </h2>
            </Link>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  )
}

export default Related
