import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import tw, { css } from 'twin.macro'

const Wrapper = styled.header`
  ${tw`relative flex w-full h-16 px-6 bg-white self-start shadow-none items-center justify-between border-b border-gray-200 z-20`}
  ${tw`md:(h-36)`}
`

const MedicalCannabisByShoppersLogo = styled.h2`
  ${tw`w-32 h-10 bg-no-repeat bg-left text-primary bg-contain`}
  ${tw`md:(w-80 h-36 ml-24)`}
  ${css`
    background-image: url('/shoppers.png');
    text-indent: -9999px;
  `}
`

const HelloMDLogoWrapper = styled.div`
  ${tw`flex flex-col`}
  ${tw`md:(flex-row items-center)`}
`

const PoweredBy = styled.span`
  ${tw`mr-4 text-gray-600`}
`

const HelloMDLogo = styled.h2`
  ${tw`w-24 h-8 bg-no-repeat bg-right text-primary ml-auto mr-4`}
  ${tw`md:(w-32 h-10 mr-24)`}
  ${css`
    background-image: url('/logo.svg');
    text-indent: -9999px;
  `}
`

const WebinarHeader = ({ className }) => {
  return (
    <Wrapper className={className}>
      <MedicalCannabisByShoppersLogo>MyMedi</MedicalCannabisByShoppersLogo>
      <HelloMDLogoWrapper>
        <PoweredBy>Powered By</PoweredBy>
        <Link to="/" title="HelloMD">
          <HelloMDLogo>HelloMD</HelloMDLogo>
        </Link>
      </HelloMDLogoWrapper>

    </Wrapper>
  )
}

export default WebinarHeader
