import Author from './Author'
import Tags from './Tags'
import Related from './Related'

const Article = {
  Author,
  Tags,
  Related,
}

export default Article
