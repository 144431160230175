import React from 'react'
import tw, { styled } from 'twin.macro'

import { SEO, Header, ModalHandler } from '@components'

import Footer from './components/Footer'

const Wrapper = styled.div`
  ${tw`w-full h-full`}
`

const Content = styled.section`
  ${tw`min-h-content w-full`}
`

const StyledHeader = styled(Header)`
  ${tw`h-header`}
`

const MainLayout = ({
  children,
  className,
  shouldShowNewsletterModal = true,
}) => {
  return (
    <>
      <SEO />
      <Wrapper>
        <StyledHeader />

        <Content className={className} >
          {children}
        </Content>

        <Footer />
      </Wrapper>

      <ModalHandler shouldShowNewsletterModal={shouldShowNewsletterModal} />
    </>
  )
}

export default MainLayout
